import { IconVolumeHigh } from '@assets/icons/components/IconVolumeHigh';
import { IconVolumeLow } from '@assets/icons/components/IconVolumeLow';
import { IconVolumeMedium } from '@assets/icons/components/IconVolumeMedium';
import { IconVolumeMute } from '@assets/icons/components/IconVolumeMute';
import { IconVolumeOff } from '@assets/icons/components/IconVolumeOff';
import { VOLUME_THRESHOLD } from '@constants';
import { useDebounce } from '@dwarvesf/react-hooks';
import Slider from '@mui/material/Slider';
import AppAudio from 'core/audio';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { KColors, KContainer, KDims } from 'uikit';

const Settings = () => {
  const [volume, setVolume] = useState(AppAudio.getVolume());
  const [isMuted, setIsMuted] = useState(AppAudio.getIsMuted());

  const debouncedVolume = useDebounce(volume, 500);

  const isMobile = KDims.isMobile();

  const iconSize = useMemo(() => (isMobile ? 32 : 24), [isMobile]);

  const renderIcon = useMemo(() => {
    let Icon: any;
    if (isMuted) {
      Icon = debouncedVolume === 0 ? IconVolumeMute : IconVolumeOff; // Show mute or off icon
    } else if (debouncedVolume > VOLUME_THRESHOLD.MEDIUM) {
      Icon = IconVolumeHigh; // Show high volume icon
    } else if (debouncedVolume > VOLUME_THRESHOLD.LOW) {
      Icon = IconVolumeMedium; // Show medium volume icon
    } else {
      Icon = IconVolumeLow; // Show low volume icon
    }

    return (
      <Icon
        width={iconSize}
        height={iconSize}
        stroke={isMuted ? KColors.black : KColors.white}
      />
    );
  }, [debouncedVolume, iconSize, isMuted]);

  const onIconPress = useCallback(() => {
    AppAudio.toggleMute();
    setIsMuted(!isMuted);
  }, [isMuted]);

  useEffect(() => {
    AppAudio.setVolume(debouncedVolume);
  }, [debouncedVolume]);

  const onVolumeChange = useCallback((_: any, v: number) => {
    setVolume(v);
  }, []);

  return (
    <KContainer.View>
      <KContainer.View row alignItems paddingL="1rem" paddingR="1.5rem">
        <KContainer.Touchable
          onPress={onIconPress}
          br="round"
          size={iconSize + 24}
          background={
            isMuted ? KColors.grayLight.light : KColors.primary.normal
          }
          center
          marginR="1.5rem"
        >
          {renderIcon}
        </KContainer.Touchable>

        <KContainer.View flex justifyContent>
          <Slider
            aria-label="Volume"
            value={volume}
            onChange={onVolumeChange}
            step={0.1}
            min={0}
            max={1}
          />
        </KContainer.View>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(Settings);
