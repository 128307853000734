import { TABLE_STATUSES } from '@constants/gameConfigs';
import Completed from 'containers/InGame/Completed';
import Pausing from 'containers/InGame/Pausing';
import Playing from 'containers/InGame/Playing';
import Reviewing from 'containers/InGame/Reviewing';
import WaitingForReady from 'containers/InGame/WaitingForReady';
import WaitingRoom from 'containers/InGame/WaitingRoom';
import PageHeader from 'containers/PageHeader';
import { useGameContext, withGameContext } from 'context/GameContext';
import { useStudentInGameInfo } from 'hooks';
import { useDisconnect } from 'hooks/game/useDisconnect';
import React, { memo, useMemo } from 'react';
import trans from 'translation';
import { KContainer, KLabel, KDims } from 'uikit';

const StudentInGame = () => {
  const { roomInfo, isCompleted } = useGameContext();

  const { myTable, isPauseRequestor } = useStudentInGameInfo();

  const status = myTable?.status;

  const renderContent = useMemo(() => {
    if (isCompleted) {
      return <Completed />;
    }

    switch (status) {
      case TABLE_STATUSES.TERMINATED:
        return <KLabel.Text>This game has been terminated</KLabel.Text>;

      case TABLE_STATUSES.WAITING:
      case TABLE_STATUSES.WAITING_FOR_READY:
        return <WaitingForReady />;

      case TABLE_STATUSES.PLAYING:
        return <Playing />;

      case TABLE_STATUSES.PAUSING:
        return <Pausing isPauseRequestor={isPauseRequestor} />;

      case TABLE_STATUSES.REVIEWING:
        return <Reviewing />;

      default:
        return <WaitingRoom />;
    }
  }, [isCompleted, isPauseRequestor, status]);

  const isWaiting = !myTable;

  const isMobile = KDims.isMobile();

  useDisconnect();

  return (
    <KContainer.View flex>
      <KContainer.View paddingH={isMobile ? '1.5rem' : undefined}>
        <PageHeader
          title={
            isWaiting
              ? trans('common.waiting_room')
              : myTable?.status === TABLE_STATUSES.COMPLETED
              ? trans('common.game_finished')
              : `${roomInfo?.gameSetup?.type || ''} Game`
          }
          titleTypo={isWaiting ? 'TextLgNormal' : 'TextLgBold'}
          showBackButton={isWaiting}
        />
      </KContainer.View>

      {renderContent}
    </KContainer.View>
  );
};

StudentInGame.displayName = 'Screen.StudentInGame';

export default memo(withGameContext(StudentInGame));
