import { IRoomDto } from '@dto';
import {
  HubConnectionBuilder,
  HubConnection,
  HubConnectionState
} from '@microsoft/signalr';

export interface ISocketMessage {
  data: string;
}

export interface ISocketMessageParsedData {
  code: number;
  data: IRoomDto;
  msg: string;
  type: SIGNALR_EVENTS;
}

const MAX_ATTEMPTS = 3;

class AppSignalR {
  pingInterval: NodeJS.Timer | undefined = undefined;
  connection: HubConnection | undefined = undefined;
  attempts: number = 0;
  isLoading: boolean = false;

  constructor() {}

  init = async (room: number | string, id: string) => {
    console.log('[SIGNALR][ATTEMPTS]', this.attempts);
    if (!this.connection) {
      console.log('[SIGNALR] => init', room);

      const origin = process.env.REACT_APP_SIGNALR as string;

      this.connection = new HubConnectionBuilder()
        .withUrl(`${origin}?userId=${id}&room=${room}`)
        .withAutomaticReconnect()
        .withServerTimeout(45000)
        .withKeepAliveInterval(20000)
        // .configureLogging(LogLevel.Information)
        .build();

      this.connection.onreconnecting(e => {
        console.log('[SIGNALR] => reconnecting', e);
      });

      this.connection.onclose = () => {
        console.log('[SIGNALR][DISCONNECTED]');
        // this.connection = undefined;

        if (this.pingInterval) {
          clearInterval(this.pingInterval);
          this.pingInterval = undefined;
        }

        // if (this.attempts < MAX_ATTEMPTS) {
        //   console.log('[SIGNALR] => waiting for reconnect');
        //   setTimeout(() => {
        //     this.attempts += 1;
        //     this.init(port, id);
        //   }, 5000);
        // }
      };

      await this.connect();
    }
  };

  connect = async () => {
    if (this.connection) {
      console.log('[SIGNALR] => connect');
      try {
        await this.connection.start();
        console.log('[SIGNALR][CONNECTED]');
        if (!this.pingInterval) {
          this.pingInterval = setInterval(() => {
            this.emit(SIGNALR_EVENTS.PING);
          }, 30000);
        }
      } catch (e) {
        console.log('[SIGNALR][CONNECT FAILED]', e);
      }
    }
  };

  on = (cb?: (data: ISocketMessageParsedData) => void) => {
    if (this.connection) {
      const _d = (d: string): ISocketMessageParsedData => {
        return JSON.parse(d);
      };

      this.connection.on('Broadcast', d => {
        if (d) {
          cb?.(_d(d));
        }
      });

      this.connection.on('Send', d => {
        if (d) {
          cb?.(_d(d));
        }
      });
    }
  };

  emit = async (ev: string, data?: any) => {
    if (
      this.connection &&
      this.connection.state === HubConnectionState.Connected
    ) {
      console.log('[SIGNALR] => emit', ev, data);
      try {
        const jsonData = { ...data, type: ev };
        console.log('[SIGNALR] => SendMessage', JSON.stringify(jsonData));
        await this.connection.send('SendMessage', JSON.stringify(jsonData));
        console.log('[SIGNALR] => emit success');
      } catch (e) {
        console.log('[SIGNALR][EMIT FAILED]', e);
      }
    }
  };

  emitWithLoading = async (ev: string, data?: any) => {
    if (
      this.connection &&
      this.connection.state === HubConnectionState.Connected
    ) {
      console.log('[SIGNALR] => emit', ev, data);
      try {
        const jsonData = { ...data, type: ev };
        await this.connection.invoke('SendMessage', JSON.stringify(jsonData));
      } catch (e) {
        console.log('[SIGNALR][EMIT FAILED]', e);
      }
    }
  };

  disconnect = async () => {
    console.log('[SIGNALR] => disconnect');
    this.attempts = MAX_ATTEMPTS;
    // if (this.pingInterval) {
    //   clearInterval(this.pingInterval);
    //   this.pingInterval = undefined;
    // }
    if (this.connection) {
      await this.connection.stop();
      this.connection = undefined;

      if (this.pingInterval) {
        clearInterval(this.pingInterval);
        this.pingInterval = undefined;
      }
    }
  };
}

export default new AppSignalR();

export enum SIGNALR_EVENTS {
  PING = 'PING',
  PONG = 'PONG',
  JOIN_ROOM = 'JOIN_ROOM',
  DISCONNECT = 'DISCONNECT',
  UPDATE_ROOM_SETTINGS = 'UPDATE_ROOM_SETTINGS',
  UPDATE_SCORE = 'UPDATE_SCORE',
  SET_FINAL_SCORE = 'SET_FINAL_SCORE',
  UPDATE_ROOM_STATUS = 'UPDATE_ROOM_STATUS',
  READY = 'READY',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  START_GAME = 'START_GAME',
  REMAINING_TIME = 'REMAINING_TIME',
  START_WORD = 'START_WORD',
  WORD_TIME_UP = 'WORD_TIME_UP',
  TERMINATE_ROOM = 'TERMINATE_ROOM',
  EMERGENCY_END = 'EMERGENCY_END',
  RESTART_GAME = 'RESTART_GAME',
  CHANGE_DESCRIBER = 'CHANGE_DESCRIBER'
}
