import { IconFolderSearch } from '@assets/icons/components/IconFolderSearch';
import { MUTATION_KEYS } from '@constants';
import { E_GAME_MODES, E_RACE_MODES, GAMES } from '@constants/gameConfigs';
import loadable from '@loadable/component';
import AppValidation from 'core/validation';
import isNumber from 'lodash/isNumber';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';
import * as yup from 'yup';

const SaveToFolder = loadable(() => import('./components/SaveToFolder'));

export interface IFormDataWord {
  keyword: string;
  bannedWords: string[];
}

export interface IFormData {
  id?: number;
  label?: string;
  numberOfTeams: number;
  numberOfStudents: number;
  raceMode: number;
  gameMode?: number;
  timeLimit: {
    min: number;
    sec: number;
  };
  words: IFormDataWord[];
  isLaunchGame: boolean;
}

export const schema = () =>
  AppValidation.init({
    timeLimit: yup
      .object()
      .shape({
        min: yup.number(),
        sec: yup.number()
      })
      .test('limit', '', function (v: any) {
        const { raceMode } = this.parent;
        const { min, sec } = v;
        if (
          raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id &&
          isNumber(min) &&
          isNumber(sec)
        ) {
          if (min * 60 + sec > 300) {
            return this.createError({
              message: 'Maximum time limit is 5 mins'
            });
          }
          if (min * 60 + sec < 60) {
            return this.createError({
              message: 'Minimum time limit is 1 min'
            });
          }
        }
        return true;
      }),
    words: yup.array().of(
      yup.object().shape({
        keyword: AppValidation.required(trans('validation.required_keyword'))
        // banneds: yup.array()
      })
    )
  });

export const makeUniqueInputId = (key: string) => (name: string) => {
  return `${key}.${name}`;
};

export const onLaunchGame = (mParams: any) => {
  const { isLaunchGame } = mParams;

  if (mParams.roleTime === E_RACE_MODES.TIME_LIMIT_WORD.id) {
    const { min, sec } = mParams;
    if (isNumber(min) && isNumber(sec) && min * 60 + sec > 300) {
      UIUtils.snackBar.open({
        message: 'Time limit maximum is 5 mins',
        status: 'danger'
      });
      return;
    }
  }

  if (mParams.type === GAMES.TABOO && mParams.contents.length % 2 !== 0) {
    UIUtils.snackBar.open({
      message: 'Number of words must be even',
      status: 'danger'
    });
  } else if (
    mParams.type === GAMES.TABOO &&
    mParams.rolePlay === E_GAME_MODES.WHOLE_CLASS.id &&
    mParams.numberOfStudents > mParams.contents.length / 2
  ) {
    UIUtils.snackBar.open({
      message:
        'Number of students must be less or equal than half of the number of words',
      status: 'danger'
    });
  } else if (
    mParams.type === GAMES.HOTSEAT &&
    mParams.numberOfStudents > mParams.contents.length
  ) {
    UIUtils.snackBar.open({
      message: 'Number of students must be less or equal than number of words',
      status: 'danger'
    });
  } else {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconFolderSearch
            stroke={KColors.primary.normal}
            fill={KColors.transparent}
          />
        )
      },
      maxWidth: 'xs',
      title: trans(
        isLaunchGame ? 'common.launch_game' : 'common.save_to_a_folder'
      ),
      content: <SaveToFolder data={mParams} />,
      mutationKey: [MUTATION_KEYS.launchGame]
    });
  }
};
