import { IS_TEACHER } from '@constants';
import { useGameContext } from 'context/GameContext';
import React, { memo } from 'react';
import trans from 'translation';
import { KButton, KContainer, KDims } from 'uikit';

import { useGainLosePoint } from '../helpers';
import InGameImage from '../InGame.Image';

interface IProps {
  isPauseRequestor?: boolean;
}

const Pausing = ({ isPauseRequestor }: IProps) => {
  const { onRequestResume, onEmergencyEnd } = useGameContext();

  const { onGainLosePoint } = useGainLosePoint();

  const isMobile = KDims.isMobile();

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri={isMobile ? 'game-paused.webp' : 'game-paused-2.webp'}
        background="#09AEC3"
      >
        {!isMobile && isPauseRequestor && (
          <KContainer.View
            row
            alignItems
            gap="1.5rem"
            width="100%"
            marginT="3rem"
          >
            <KButton.Outline
              flex
              title={trans('common.gain_or_lose_a_point')}
              onPress={() => onGainLosePoint()}
            />

            <KButton.Solid
              flex
              title={trans('common.resume_game')}
              onPress={onRequestResume}
            />
          </KContainer.View>
        )}
      </InGameImage>

      {isMobile && isPauseRequestor && (
        <>
          <KButton.Outline
            marginT="1.5rem"
            width={'100%'}
            title={trans('common.gain_or_lose_a_point')}
            onPress={() => onGainLosePoint()}
          />

          <KButton.Solid
            marginT="1.5rem"
            width={'100%'}
            title={trans('common.resume_game')}
            onPress={onRequestResume}
          />
        </>
      )}

      {IS_TEACHER && (
        <KButton.Solid
          marginT="1.5rem"
          width={'100%'}
          title={trans('common.emergency_end')}
          onPress={onEmergencyEnd}
        />
      )}
    </KContainer.View>
  );
};

Pausing.displayName = 'Containers.InGame.Pausing';

export default memo(Pausing);
