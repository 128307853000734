import { IconSync } from '@assets/icons/components/IconSync';
import { EventName } from '@constants';
// import { E_GAME_MODES, WORD_STATUSES } from '@constants/gameConfigs';
import { IRoomDto } from '@dto';
import { useGameContext } from 'context/GameContext';
import AppEvent from 'core/event';
// import { useCurrentAuth } from 'hooks/auth';
import React, { memo, useCallback, useEffect, useState } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel } from 'uikit';
import { UIUtils } from 'utils';

const LostConnection = memo(() => {
  const [seconds, setSeconds] = useState(120); // 2 minutes;
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prev => prev - 1);
      }, 1000); // Decrement every second
    } else if (seconds === 0) {
      setIsActive(false); // Stop the countdown when it reaches zero
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const formatTime = (s: number): string => {
    const minutes = Math.floor(s / 60);
    const remainingSeconds = s % 60;
    const formattedMinutes = String(minutes).padStart(2, '0'); // Ensures two digits
    const formattedSeconds = String(remainingSeconds).padStart(2, '0'); // Ensures two digits
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <KContainer.View dp="flex" alignItems marginH="1.5rem">
      <KLabel.Text>{trans('common.lost_connection_message')}</KLabel.Text>

      <KContainer.View
        row
        alignItems
        gap="1.5rem"
        width="100%"
        marginT="1.5rem"
      >
        {/* <KButton.Solid flex title={trans('common.restart_game')} /> */}

        <KButton.Solid
          flex
          title={trans('common.continue') + ` (${formatTime(seconds)})`}
          disabled={isActive}
        />
      </KContainer.View>
    </KContainer.View>
  );
});

export const useDisconnect = () => {
  // const [time, setTime] = useState<number | undefined>(undefined);
  // const { id: myId } = useCurrentAuth();
  // const {} = useGameContext();

  const onShowDisconnectPopup = useCallback(
    ({ detail: roomInfo }: { detail: IRoomDto }) => {
      const { teams, users, gameSetup, inactiveUsers } = roomInfo || {};

      UIUtils.popup.open({
        id: 'disconnect',
        headIcon: {
          jsx: (
            <IconSync
              stroke={KColors.primary.normal}
              fill={KColors.transparent}
            />
          )
        },
        maxWidth: 'xs',
        title: trans('common.lost_connection'),
        content: <LostConnection />
        // content: <MoveToFolder data={item} />,
        // mutationKey: [MUTATION_KEYS.moveGame]
      });
    },
    []
  );

  useEffect(() => {
    AppEvent.subscribe(EventName.DISCONNECT, onShowDisconnectPopup as any);
    return () => {
      AppEvent.unsubscribe(EventName.DISCONNECT, onShowDisconnectPopup as any);
    };
  }, [onShowDisconnectPopup]);
  // return time;
};
