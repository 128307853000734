import { IconLogout } from '@assets/icons/components/IconLogout';
import { EventName, IS_TEACHER } from '@constants';
import { E_GAME_MODES, GAMES, TABLE_STATUSES } from '@constants/gameConfigs';
import { IGameDto, IRoomDto, ITeamDto } from '@dto';
import AppAudio from 'core/audio';
import AppEvent from 'core/event';
import Prototype from 'core/prototype';
import AppSignalR, { SIGNALR_EVENTS } from 'core/signalR';
import {
  useCurrentAuth,
  useStudentInGameInfo,
  useTeacherInGameInfo
} from 'hooks';
import { useFetchRoom } from 'hooks/room';
import { usePopup } from 'hooks/usePopup';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import trans from 'translation';
import { useMount } from 'uikit';
import { UIUtils } from 'utils';

import { useAuthContext } from './AuthContext';

type TypeGameContext = {
  roomId: string;
  roomInfo: IRoomDto | undefined;
  gameInfo: IGameDto | undefined;
  gameMode: number | undefined;
  raceMode: number | undefined;
  isReviewing: boolean;
  isCompleted: boolean;
  isChaoticMode: boolean;
  isWholeClassMode: boolean;
  isHotseat: boolean;
  tableWithTeams: string[];

  isFetchingRoomDetails: boolean;
  isErrorFetchRoomDetails: boolean;

  showReviewingWords: boolean;
  toggleShowReviewingWords: () => void;

  reviewingTeam: ITeamDto | undefined;
  reviewingTeamIndex: number | undefined;
  isLastReviewingTeam: boolean;
  setReviewingTeam: (team: ITeamDto | undefined) => void;
  setTeamLocalScore: (teamId: string, score?: number) => void;
  setPreviousReviewingTeam: () => void;

  setRoomInfo: (room: IRoomDto) => void;
  refetchRoomInfo: () => void;
  onStudentReady: () => void;

  onStartWord: () => void;
  onRequestPause: () => void;
  onRequestResume: () => void;
  onRequestUpdateScore: (score: number, teamId?: string) => void;
  onRequestSetFinalScore: (teamId: string, totalScore: number) => void;
  onRequestTerminate: () => void;
  onEmergencyEnd: () => void;
};

const GameContext = React.createContext<TypeGameContext>({
  roomId: '',
  roomInfo: undefined,
  gameInfo: undefined,
  gameMode: undefined,
  raceMode: undefined,
  isReviewing: false,
  isCompleted: false,
  isChaoticMode: false,
  isWholeClassMode: false,
  isHotseat: false,
  isErrorFetchRoomDetails: false,
  isFetchingRoomDetails: false,
  tableWithTeams: [],

  showReviewingWords: true,
  reviewingTeam: undefined,
  reviewingTeamIndex: -1,
  isLastReviewingTeam: false,

  toggleShowReviewingWords: () => {},
  setReviewingTeam: () => {},
  setTeamLocalScore: () => {},
  setPreviousReviewingTeam: () => {},

  setRoomInfo: () => {},
  refetchRoomInfo: () => {},
  onStudentReady: () => {},

  onStartWord: () => {},
  onRequestPause: () => {},
  onRequestResume: () => {},
  onRequestUpdateScore: () => {},
  onRequestSetFinalScore: () => {},
  onRequestTerminate: () => {},
  onEmergencyEnd: () => {}
});

let timeoutInstance: NodeJS.Timer | null = null;

export const GameContextProvider = (props: React.PropsWithChildren) => {
  const { roomId = '' } = useParams();

  const navigate = useNavigate();

  const { setStudentInfo } = useAuthContext();

  const currentAuth = useCurrentAuth();

  const [roomInfo, setRoomInfo] = useState<IRoomDto | undefined>(undefined);
  // ONLY FOR REVIEWING
  const [showReviewingWords, setShowReviewingWords] = useState(true);
  const [reviewingTeam, setReviewingTeam] = useState<ITeamDto | undefined>(
    undefined
  );
  const [reviewingTeamIndex, setReviewingTeamIndex] = useState<number>(-1);
  const [scores, setScores] = useState<Record<string, number>>({});

  const { teams = [], tables = [] } = roomInfo || {};

  const isLastReviewingTeam = useMemo(() => {
    if (!reviewingTeam || isEmpty(teams)) {
      return false;
    }

    return teams[teams.length - 1].id === reviewingTeam.id;
  }, [reviewingTeam, teams]);

  const toggleShowReviewingWords = useCallback(() => {
    if (showReviewingWords) {
      setShowReviewingWords(false);
      let _scores = scores;
      if (isEmpty(scores)) {
        const defScores = teams.reduce<Record<string, number>>((acc, cur) => {
          const teamScore = (cur.words ?? []).reduce((_acc, _cur) => {
            _acc += _cur.score ?? 0;
            return _acc;
          }, 0);
          acc[cur.id] = teamScore;
          return acc;
        }, {});
        _scores = defScores;
        setScores(defScores);
      }
      setReviewingTeam({
        ...teams[0],
        finalScore: _scores[teams[0].id]
      });
      setReviewingTeamIndex(0);
    } else {
      setShowReviewingWords(true);
      setReviewingTeam(undefined);
      setReviewingTeamIndex(-1);
    }
  }, [scores, showReviewingWords, teams]);

  const setPreviousReviewingTeam = useCallback(() => {
    if (reviewingTeamIndex === 0) {
      toggleShowReviewingWords();
    } else {
      const prevTeam = teams[reviewingTeamIndex - 1];
      if (prevTeam) {
        setReviewingTeam({
          ...prevTeam,
          finalScore: scores[prevTeam.id]
        });
        setReviewingTeamIndex(reviewingTeamIndex - 1);
      }
    }
  }, [reviewingTeamIndex, scores, teams, toggleShowReviewingWords]);

  const _setRoomInfo = useCallback((d?: IRoomDto) => {
    if (!d || isEmpty(d)) {
      setRoomInfo(undefined);
    } else {
      const { gameSetup, ..._d } = d;
      setRoomInfo({
        ..._d,
        gameSetup: {
          ...gameSetup,
          raceMode: gameSetup?.roleTime,
          gameMode: gameSetup?.rolePlay
        }
      });
    }
  }, []);

  const isReviewing = useMemo(() => {
    // STATUS = REVIEWING ONLY WHEN ALL TABLE ARE REVIEWING OR COMPLETED, AND AT LEAST 1 TABLE IS REVIEWING
    if (!isEmpty(tables)) {
      return (
        tables.every(
          i =>
            i.status === TABLE_STATUSES.REVIEWING ||
            i.status === TABLE_STATUSES.COMPLETED
        ) && tables.some(i => i.status === TABLE_STATUSES.REVIEWING)
      );
    }

    return false;
  }, [tables]);

  const isCompleted = useMemo(() => {
    // STATUS = COMPLETED ONLY WHEN ALL TABLES ARE COMPLETED
    if (!isEmpty(tables)) {
      return tables.every(i => i.status === TABLE_STATUSES.COMPLETED);
    }

    return false;
  }, [tables]);

  const { myTable, myTeam, opposingTeam } = useStudentInGameInfo(roomInfo);
  const { runningTable, runningTeam } = useTeacherInGameInfo(roomInfo);

  const {
    isPending,
    isLoadingError,
    refetch: refetchRoomInfo
  } = useFetchRoom(roomId, d => {
    if (!isEqual(d, roomInfo)) {
      _setRoomInfo(d);
    }
  });

  const payloadSocket = useMemo(() => {
    const cParams = {
      id: currentAuth.id,
      role: currentAuth.role,
      username: currentAuth.username
    };

    if (IS_TEACHER) {
      return {
        ...cParams,
        tableId: runningTable?.id,
        teamId: runningTeam?.id
      };
    }

    return {
      ...cParams,
      tableId: myTable?.id,
      teamId: myTeam?.id
    };
  }, [
    currentAuth.id,
    currentAuth.role,
    currentAuth.username,
    myTable?.id,
    myTeam?.id,
    runningTable?.id,
    runningTeam?.id
  ]);

  const { onRemovePopup } = usePopup();

  const onRoomTerminated = useCallback(() => {
    if (!IS_TEACHER) {
      onRemovePopup({
        title: trans('common.terminated'),
        content: trans('common.room_is_terminated_message'),
        buttons: [
          {
            variant: 'outline',
            title: trans('common.close')
          }
        ]
      });
    }

    navigate(-1);
  }, [navigate, onRemovePopup]);

  const onSocketCb = useCallback(
    (response: { type: SIGNALR_EVENTS; data: IRoomDto }) => {
      const { data, type } = response || {};
      if (type !== SIGNALR_EVENTS.REMAINING_TIME) {
        console.log('[SIGNALR] => onMessage', response);
      }

      switch (type) {
        case SIGNALR_EVENTS.JOIN_ROOM:
        case SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS:
        case SIGNALR_EVENTS.PAUSE:
        case SIGNALR_EVENTS.RESUME:
        case SIGNALR_EVENTS.READY:
        // case SIGNALR_EVENTS.DISCONNECT:
        case SIGNALR_EVENTS.SET_FINAL_SCORE:
        case SIGNALR_EVENTS.UPDATE_SCORE:
        case SIGNALR_EVENTS.START_GAME:
        case SIGNALR_EVENTS.START_WORD:
        case SIGNALR_EVENTS.UPDATE_ROOM_STATUS:
        case SIGNALR_EVENTS.WORD_TIME_UP:
        case SIGNALR_EVENTS.RESTART_GAME:
        case SIGNALR_EVENTS.EMERGENCY_END:
          _setRoomInfo(data);

          if (
            type === SIGNALR_EVENTS.JOIN_ROOM &&
            timeoutInstance &&
            data.inactiveUsers.length === 0 &&
            data.tables.length > 0
          ) {
            clearTimeout(timeoutInstance);
            timeoutInstance = null;
            UIUtils.popup.dismissById('disconnect');
          }

          // Play sound when 1 user joined room
          // if (type === SIGNALR_EVENTS.JOIN_ROOM) {
          //   AppAudio.playSound('/sounds/join_game.mp3', {
          //     keepRunningAfterSwitch: true
          //   });
          // }

          // Save student state
          if (
            [
              SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS,
              SIGNALR_EVENTS.START_GAME
            ].includes(type) &&
            !IS_TEACHER
          ) {
            const newAuth = data.users.find(
              (u: any) => u.id === currentAuth.id
            );
            if (newAuth) {
              setStudentInfo(newAuth);
            }
          }

          break;

        case SIGNALR_EVENTS.DISCONNECT:
          _setRoomInfo(data);

          // if (data.inactiveUsers.length > 0 && data.tables.length > 0) {
          // if (timeoutInstance) {
          //   clearTimeout(timeoutInstance);
          //   timeoutInstance = null;
          // }

          // const _teams = data.teams.filter(t =>
          //   data.inactiveUsers.some(i => i.teamId === t.id)
          // );
          // const _tableIds = _teams.map(t => t.tableId);

          // if (
          //   IS_TEACHER ||
          //   (!IS_TEACHER && isChaoticMode && _tableIds.includes(myTable?.id))
          // ) {
          //   timeoutInstance = setTimeout(() => {
          //     console.log('OPEN POPUP');
          //     AppEvent.dispatch(EventName.DISCONNECT, {
          //       ...data,
          //       gameSetup: {
          //         ...data.gameSetup,
          //         raceMode: data.gameSetup?.roleTime,
          //         gameMode: data.gameSetup?.rolePlay
          //       }
          //     });
          //   }, 3000);
          // }
          // }
          break;

        case SIGNALR_EVENTS.REMAINING_TIME:
          if (data) {
            AppEvent.dispatch(EventName.WORD_REMAINING_TIME_CHANGED, {
              ...data,
              gameSetup: {
                ...data.gameSetup,
                raceMode: data.gameSetup?.roleTime,
                gameMode: data.gameSetup?.rolePlay
              }
            });
          }
          break;

        case SIGNALR_EVENTS.TERMINATE_ROOM:
          onRoomTerminated();
          break;

        default:
          return;
      }
    },
    [_setRoomInfo, currentAuth.id, onRoomTerminated, setStudentInfo]
  );

  const initSignalR = useCallback(async () => {
    if (roomId) {
      console.log('[GAME CONTEXT] => init room');
      await AppSignalR.init(roomId, currentAuth.id);

      AppSignalR.connection?.onreconnected(() => {
        refetchRoomInfo();
      });
    }
  }, [currentAuth.id, refetchRoomInfo, roomId]);

  useMemo(() => {
    initSignalR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    AppSignalR.on(onSocketCb);
  }, [onSocketCb]);

  useMount(() => {
    return () => {
      console.log('[GAME CONTEXT] => disconnect');
      AppSignalR.disconnect();
    };
  });

  const onStudentReady = useCallback(() => {
    AppAudio.playSound('/sounds/ready.mp3', {
      cb: () => AppSignalR.emit(SIGNALR_EVENTS.READY, payloadSocket)
    });
  }, [payloadSocket]);

  const onStartWord = useCallback(() => {
    AppAudio.playSound('/sounds/start_word.mp3', {
      cb: () => AppSignalR.emit(SIGNALR_EVENTS.START_WORD, payloadSocket)
    });
  }, [payloadSocket]);

  const onRequestPause = useCallback(() => {
    AppAudio.playSound('/sounds/pause_game.mp3', {
      cb: () => AppSignalR.emit(SIGNALR_EVENTS.PAUSE, payloadSocket)
    });
  }, [payloadSocket]);

  const onRequestResume = useCallback(() => {
    AppSignalR.emit(SIGNALR_EVENTS.RESUME, payloadSocket);
  }, [payloadSocket]);

  const onRequestUpdateScore = useCallback(
    (score: number, teamId?: string) => {
      AppSignalR.emit(SIGNALR_EVENTS.UPDATE_SCORE, {
        ...payloadSocket,
        score,
        teamId:
          teamId ||
          (!IS_TEACHER &&
          roomInfo?.gameSetup?.gameMode === E_GAME_MODES.CHAOTIC.id
            ? opposingTeam?.id
            : payloadSocket.teamId)
      });
    },
    [opposingTeam?.id, payloadSocket, roomInfo?.gameSetup?.gameMode]
  );

  const onRequestSetFinalScore = useCallback(
    (teamId: string, score: number) => {
      AppSignalR.emit(SIGNALR_EVENTS.SET_FINAL_SCORE, {
        ...payloadSocket,
        score,
        teamId
      });
    },
    [payloadSocket]
  );

  // ONLY TEACHER
  const onRequestTerminate = useCallback(() => {
    AppSignalR.emit(SIGNALR_EVENTS.TERMINATE_ROOM, {});
  }, []);

  const setTeamLocalScore = useCallback(
    (teamId: string, score?: number) => {
      if (!Prototype.core.isNullOrUndefined(score)) {
        setScores({
          ...scores,
          [teamId]: score as number
        });
      }

      onRequestSetFinalScore(teamId, score ?? scores[teamId]);

      if (isLastReviewingTeam) {
        // onRequestSetFinalScore();
      } else {
        const newTeam = teams[reviewingTeamIndex + 1];
        if (newTeam) {
          setReviewingTeam({
            ...newTeam,
            finalScore: scores[newTeam.id]
          });
          setReviewingTeamIndex(reviewingTeamIndex + 1);
        }
      }
    },
    [
      isLastReviewingTeam,
      onRequestSetFinalScore,
      reviewingTeamIndex,
      scores,
      teams
    ]
  );

  const onEmergencyEnd = useCallback(() => {
    onRemovePopup({
      headIcon: {
        background: '#ffccd7',
        jsx: <IconLogout width={44} height={44} stroke="#f77777" />
      },
      title: trans('common.emergency_end'),
      content: trans('common.emergency_end_message'),
      buttons: [
        {
          variant: 'outline',
          title: trans('common.no')
        },
        {
          key: 'main',
          variant: 'solid',
          title: trans('common.yes'),
          onPress: () => AppSignalR.emit(SIGNALR_EVENTS.EMERGENCY_END, {}),
          withDismiss: true
        }
      ]
    });
  }, [onRemovePopup]);

  // const onRestartGame = useCallback(() => {
  //   AppSignalR.emit(SIGNALR_EVENTS.RESTART_GAME, {});
  // }, []);

  // const onChangeDescriber = useCallback(() => {
  //   AppSignalR.emit(SIGNALR_EVENTS.CHANGE_DESCRIBER, {});
  // }, []);

  const isChaoticMode =
    roomInfo?.gameSetup?.gameMode === E_GAME_MODES.CHAOTIC.id;

  const isWholeClassMode =
    roomInfo?.gameSetup?.gameMode === E_GAME_MODES.WHOLE_CLASS.id;

  const isHotseat = roomInfo?.gameSetup?.type === GAMES.HOTSEAT;

  const tableWithTeams = useMemo(() => {
    if (!isEmpty(tables) && !isEmpty(teams)) {
      return tables.map(i => {
        const _teams = teams.reduce<string[]>((acc, cur) => {
          if (cur.tableId === i.id) {
            acc.push(cur.name);
          }
          return acc;
        }, []);

        return _teams.join(' - ');
      });
    }

    return [];
  }, [tables, teams]);

  return (
    <GameContext.Provider
      value={{
        roomId,
        roomInfo,
        gameInfo: roomInfo?.gameSetup,
        gameMode: roomInfo?.gameSetup?.gameMode,
        raceMode: roomInfo?.gameSetup?.raceMode,
        isReviewing,
        isCompleted,
        isChaoticMode,
        isWholeClassMode,
        isHotseat,
        tableWithTeams,

        isFetchingRoomDetails: isPending,
        isErrorFetchRoomDetails: isLoadingError,

        showReviewingWords,
        reviewingTeam,
        isLastReviewingTeam,
        reviewingTeamIndex,

        toggleShowReviewingWords,
        setReviewingTeam,
        setTeamLocalScore,
        setPreviousReviewingTeam,

        setRoomInfo: _setRoomInfo,
        refetchRoomInfo,
        onStudentReady,
        onStartWord,
        onRequestPause,
        onRequestResume,
        onRequestSetFinalScore,
        onRequestUpdateScore,
        onRequestTerminate,
        onEmergencyEnd
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
};

export const withGameContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return React.memo((props: P) => {
    return (
      <GameContextProvider>
        <WrappedComponent {...props} />
      </GameContextProvider>
    );
  });
};

export const useGameContext = () => {
  return useContext(GameContext);
};
