import {
  ENDPOINTS,
  EventName,
  IS_TEACHER,
  MUTATION_KEYS,
  QUERY_KEYS,
  ROLE,
  authRef,
  updateAuthorizationToken
} from '@constants';
import {
  E_GAME_MODES,
  GAMES,
  TABLE_STATUSES,
  WORD_STATUSES
} from '@constants/gameConfigs';
import { IRoomDto, IStudentDto, ITeacherDto } from '@dto';
import { googleLogout } from '@react-oauth/google';
import {
  IChangePasswordParams,
  IGoogleVerifyParams,
  IResetPasswordParams,
  ISignInParams,
  IUpdateAccountParams
} from '@request-dto';
import { ISignInResponse } from '@response-dto';
import APIManager from '@services';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useAuthContext } from 'context/AuthContext';
import { useGameContext } from 'context/GameContext';
import AppEvent from 'core/event';
import AppStorage, { STORAGE_KEYS } from 'core/storage';
import { useMemo } from 'react';

import { useMutationEnhancer } from '../core';

export const useUser = () => {
  const { data: user, ...rest } = useQuery<ITeacherDto>({
    queryKey: [QUERY_KEYS.user],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.account('GetProfile')
      });

      if (res.data) {
        const curUser = AppStorage.get(STORAGE_KEYS.user);
        AppStorage.set(STORAGE_KEYS.user, { ...curUser, ...res.data });
        return res.data;
      }

      AppStorage.delete(STORAGE_KEYS.user);
      return null;
    },
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    initialData: AppStorage.get(STORAGE_KEYS.user),
    placeholderData: keepPreviousData
  });

  return {
    user,
    ...rest
  };
};

export const useSignIn = () => {
  const { refetch } = useUser();

  return useMutationEnhancer<ISignInResponse, ISignInParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.auth('LoginByPassword'),
        method: 'POST',
        body: data
      });

      return res.data;
    },
    onSuccess: res => {
      if (res?.accessToken) {
        updateAuthorizationToken(res.accessToken);
        AppStorage.set(STORAGE_KEYS.user, res);
        refetch();
        authRef.current?.dismiss();
      }
    }
  });
};

export const useGoogleVerify = () => {
  const { refetch } = useUser();

  return useMutationEnhancer<ISignInResponse, IGoogleVerifyParams>({
    mutationKey: [MUTATION_KEYS.googleVerify],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.auth('LoginByGoogle'),
        method: 'POST',
        body: data
      });

      return res.data;
    },
    onSuccess: res => {
      if (res?.accessToken) {
        updateAuthorizationToken(res.accessToken);
        AppStorage.set(STORAGE_KEYS.user, res);
        refetch();
        setTimeout(() => {
          authRef.current?.dismiss();
        }, 0);
      }
    }
  });
};

export const useGoogleVerifyWithCode = () => {
  const { refetch } = useUser();

  return useMutationEnhancer<ISignInResponse, string>({
    mutationKey: [MUTATION_KEYS.googleVerifyWithCode],
    mutationFn: async authorizationCode => {
      const res = await APIManager.request({
        url: ENDPOINTS.auth('LoginByGoogle', 2),
        method: 'POST',
        body: {
          authorizationCode
        }
      });

      return res.data;
    },
    onSuccess: res => {
      if (res?.accessToken) {
        updateAuthorizationToken(res.accessToken);
        AppStorage.set(STORAGE_KEYS.user, res);
        refetch();
        setTimeout(() => {
          authRef.current?.dismiss();
        }, 0);
      }
    }
  });
};

export const useSignUp = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.auth('Register'),
        method: 'POST',
        body: data,
        showToast: true
      });

      return res.success;
    },
    onSuccess: res => {
      if (res) {
        authRef.current?.setTabIndex(0);
      }
    }
  });
};

export const useSignOut = () => {
  const queryClient = useQueryClient();

  return useMutationEnhancer({
    mutationFn: () => {
      return Promise.resolve(true);
    },
    onSuccess: () => {
      googleLogout();
      updateAuthorizationToken('');
      AppStorage.delete(STORAGE_KEYS.user);
      queryClient.setQueryData([QUERY_KEYS.user], null);
      // UIUtils.popup.dismiss();
    }
  });
};

export const useForgotPassword = () => {
  return useMutationEnhancer<any, IResetPasswordParams>({
    mutationKey: [MUTATION_KEYS.resetPassword],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.auth('ResetPassword'),
        method: 'POST',
        body: data
      });

      return res.success;
    }
  });
};

export const useChangePassword = () => {
  return useMutationEnhancer<any, IChangePasswordParams>({
    mutationKey: [MUTATION_KEYS.changePassword],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.account('ChangePassword'),
        method: 'POST',
        body: data,
        showToast: true
      });

      return res.success;
    },
    onSuccess: res => {
      if (res) {
        AppEvent.dispatch(EventName.LOGOUT);
      }
    }
  });
};

export const useUpdateAccount = () => {
  const { refetch } = useUser();

  return useMutationEnhancer<any, IUpdateAccountParams>({
    mutationKey: [MUTATION_KEYS.updateAccount],
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.account('Update'),
        method: 'POST',
        body: data,
        showToast: true
      });

      return res.success;
    },
    onSuccess: (res, vars) => {
      if (res) {
        if (vars.password) {
          AppEvent.dispatch(EventName.LOGOUT);
        } else {
          refetch();
        }
      }
    }
  });
};

export const useTeacherInfo = () => {
  const { teacherInfo } = useAuthContext();

  return (teacherInfo || {}) as ITeacherDto;
};

export const useTeacherId = () => {
  const teacherInfo = useTeacherInfo() || {};
  return teacherInfo.id;
};

export const useUpdateTeacherInfo = () => {
  const { updateTeacherInfo } = useAuthContext();

  return updateTeacherInfo;
};

export const useStudentInfo = () => {
  const { studentInfo } = useAuthContext();

  return (studentInfo || {}) as IStudentDto;
};

export const useSetStudentInfo = () => {
  const { setStudentInfo } = useAuthContext();

  return setStudentInfo;
};

export const useStudentId = () => {
  const studentInfo = useStudentInfo();
  return studentInfo.id;
};

export const useStudentInGameInfo = (d?: IRoomDto) => {
  const myInfo = useStudentInfo();
  const { roomInfo: _roomInfo } = useGameContext();

  const roomInfo = _roomInfo ?? d;

  const { teams, users, tables, gameSetup } = roomInfo || {};

  const myProfile = useMemo(() => {
    return users?.find(i => i.id === myInfo?.id) || myInfo;
  }, [myInfo, users]);

  const myTeam = useMemo(
    () => teams?.find(i => i.id === myProfile.teamId),
    [myProfile.teamId, teams]
  );

  const myTable = useMemo(
    () => tables?.find(i => i.id === myTeam?.tableId),
    [myTeam?.tableId, tables]
  );

  const opposingTeam = useMemo(() => {
    if (!myTeam || gameSetup?.gameMode === E_GAME_MODES.WHOLE_CLASS.id) {
      return undefined;
    }

    return teams?.find(
      i => i.tableId === myTeam.tableId && i.id !== myTeam?.id
    );
  }, [gameSetup?.gameMode, myTeam, teams]);

  const playingTables = useMemo(() => {
    return (
      tables?.filter(
        i =>
          i.status === TABLE_STATUSES.PLAYING ||
          i.status === TABLE_STATUSES.PAUSING
      ) ?? []
    );
  }, [tables]);

  const playingTeams = useMemo(() => {
    return (
      teams?.filter(i => {
        const words = i.words ?? [];
        return words.some(
          j =>
            j.status === WORD_STATUSES.RUNNING ||
            j.status === WORD_STATUSES.TIME_UP
        );
      }) ?? []
    );
  }, [teams]);

  const playingTeamInMyTable = useMemo(() => {
    const t = teams?.filter(i => i.tableId === myTable?.id) ?? [];
    return t.find(i => {
      return (i.words ?? []).some(
        w =>
          w.status === WORD_STATUSES.RUNNING ||
          w.status === WORD_STATUSES.TIME_UP
      );
    });
  }, [myTable?.id, teams]);

  const myRunningWord = useMemo(() => {
    return myTeam?.words?.find(
      i =>
        i.status === WORD_STATUSES.RUNNING || i.status === WORD_STATUSES.TIME_UP
    );
  }, [myTeam?.words]);

  const opposingTeamRunningWord = useMemo(() => {
    return opposingTeam?.words?.find(
      i =>
        i.status === WORD_STATUSES.RUNNING || i.status === WORD_STATUSES.TIME_UP
    );
  }, [opposingTeam?.words]);

  const { isDescriber, isGuesser } = useMemo(() => {
    const _isDescriber = myRunningWord?.describerId === myInfo?.id;
    const _isGuesser = myRunningWord?.describerId !== myInfo?.id;

    return {
      isDescriber:
        gameSetup?.type === GAMES.HOTSEAT ? _isGuesser : _isDescriber,
      isGuesser: gameSetup?.type === GAMES.HOTSEAT ? _isDescriber : _isGuesser
    };
  }, [gameSetup?.type, myInfo?.id, myRunningWord?.describerId]);

  const isSupervisor = useMemo(() => {
    return gameSetup?.type !== GAMES.HOTSEAT && !!opposingTeamRunningWord;
  }, [gameSetup?.type, opposingTeamRunningWord]);

  const isPauseRequestor = useMemo(() => {
    return myTable?.pauseRequestor === myInfo?.id;
  }, [myInfo?.id, myTable?.pauseRequestor]);

  return {
    myProfile,
    myTable,
    myTeam,
    myRunningWord,
    opposingTeam,
    opposingTeamRunningWord,
    isDescriber,
    isGuesser,
    isSupervisor,
    isPauseRequestor,
    playingTables,
    playingTeams,
    playingTeamInMyTable
  };
};

export const useTeacherInGameInfo = (d?: IRoomDto) => {
  const myInfo = useTeacherInfo();
  const { roomInfo: _roomInfo } = useGameContext();

  const roomInfo = _roomInfo ?? d;

  const { teams, tables } = roomInfo || {};

  const runningTeam = useMemo(() => {
    // WHOLE CLASS MODE
    return teams?.find(t => t.isPlaying);
  }, [teams]);

  const runningTable = useMemo(() => {
    // WHOLE CLASS MODE
    // GET 1 TEAM IS PLAYING
    if (runningTeam) {
      return tables?.find(i => i.id === runningTeam.tableId);
    }

    // CHAOTIC MODE
    return undefined;
  }, [runningTeam, tables]);

  const isPauseRequestor = useMemo(() => {
    return runningTable?.pauseRequestor === `${myInfo.id}`;
  }, [myInfo.id, runningTable?.pauseRequestor]);

  return {
    myProfile: myInfo,
    runningTable,
    runningTeam,
    // myRunningWord,
    // opposingTeam,
    // opposingTeamRunningWord,
    // isDescriber,
    // isGuesser,
    // isSupervisor,
    isPauseRequestor,
    status: runningTable?.status
  };
};

export const useCurrentAuth = () => {
  const { studentInfo, teacherInfo } = useAuthContext();
  return {
    role: IS_TEACHER ? ROLE.TEACHER : ROLE.STUDENT,
    id: (IS_TEACHER ? teacherInfo?.id : studentInfo?.id) as string,
    username: (IS_TEACHER
      ? teacherInfo?.username
      : studentInfo?.username) as string
  };
};
